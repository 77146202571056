<template>
  <v-dialog v-model="dialog" persistent width="750">
    <v-card>
      <v-card-title class="text-h5">User Documents</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="userDocsList"
          :items-per-page="10"
          :loading="loading"
          class="elevation-2"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <!-- <v-list-item v-for="action in actions" :key="action" link>
              <v-list-item-title @click="listAction(action, item)">{{
                action
              }}</v-list-item-title>
            </v-list-item> -->
                <!-- <v-list-item link>
              <v-list-item-title @click="listAction('Edit', item)"
                >Edit</v-list-item-title
              >
            </v-list-item> -->
                <v-list-item link>
                  <v-list-item-title
                    ><a
                      class="doc-download"
                      :href="item.documentUrl"
                      target="_blank"
                      >Download
                      <v-icon class="ml-2"> mdi-download </v-icon>
                    </a></v-list-item-title
                  >
                </v-list-item>
                <v-list-item link>
                  <v-list-item-title @click.stop="showDeleteDialog(item)"
                    >Delete
                    <v-icon size="20px" class="ml-8">
                      mdi-delete
                    </v-icon></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" dark @click="close"> Close </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="deleteDialog" persistent width="400">
      <v-card>
        <v-card-title class="text-h5">Delete Document</v-card-title>
        <v-card-text>
          <p class="dialog-content">
            Are you sure you want to delete the document?
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="var(--primary)"
            dark
            :loading="loading"
            @click="deleteDocument"
          >
            Delete</v-btn
          >
          <v-btn color="grey darken-1" dark @click="closeDeleteDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['dialog', 'user'],
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'File Name',
        align: 'start',
        sortable: true,
        value: 'documentName',
      },
      {
        text: 'Uploaded Date',
        align: 'start',
        sortable: true,
        value: 'uploadedDate',
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'action',
      },
    ],
    deleteDialog: false,
    selectedDocument: {}
  }),
  computed: {
    ...mapGetters(['userDocsList']),
  },
  methods: {
    async getDocsList() {
      let payload = {}
      payload.user_id = this.user.user_id
      this.loading = true
      try {
        await this.$store.dispatch('get_docs_list', payload)
      } catch (error) {
        this.$root.snackbar.show({
          message: error.response.data.message,
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },
    showDeleteDialog(document) {
      this.selectedDocument = document
      this.deleteDialog = true
    },
    async deleteDocument() {
      let payload = {}
      payload.document_idx = this.selectedDocument.idx
      this.loading = true
      try {
        await this.$store.dispatch('delete_doc', payload)
        this.$root.snackbar.show({
          message: 'Document deleted successfully',
          color: 'success',
        })
      } catch (error) {
        this.$root.snackbar.show({
          message: error.response.data.message,
          color: 'error',
        })
      } finally {
        this.loading = false
        this.closeDeleteDialog() 
        this.getDocsList()  
      }
    },
    close() {
      this.$emit('refresh')
      this.$emit('update:dialog', false)
    },
    closeDeleteDialog() {
      this.deleteDialog = false
    }
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        this.getDocsList()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-content {
  font-size: 16px;
}
.doc-download {
  color: var(--subtitle);
  text-decoration: none;
}
</style>